import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom"; 
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore"; 

import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import LawyerPublicProfile from "./pages/LawyerPublicProfile";
import LawyerProfileManagement from "./pages/LawyerProfileManagement";
import ApprovalPending from "./pages/ApprovalPending";
import AdminDashboard from "./pages/AdminDashboard"; 
import LawyerReview from "./pages/LawyerReview";
import ResendEditProfile from "./pages/ResendEditProfile";
import LawyerReviewLater from "./pages/LawyerReviewLater";
import KVKK from "./pages/KVKK";  
import UserAggrement from "./pages/UserAggrement";  

const App = () => {
  const [user, setUser] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        if (currentUser.emailVerified) {
          setIsEmailVerified(true);
          await fetchUserData(currentUser.uid);
        } else {
          setIsEmailVerified(false);
          setUser(null);
        }
      } else {
        setUser(null);
        setIsEmailVerified(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUser(userData);
        setIsApproved(userData.isApproved || false);
        setIsAdmin(userData.role === "admin"); 
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-center text-xl font-semibold">Yükleniyor...</h1>
      </div>
    );
  }

  return (
    <div>
      <Navbar user={user} />
      <Routes>
        <Route path="/kullanici-sozlesmesi" element={<UserAggrement />} />
        <Route path="/kvkk" element={<KVKK />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/login"
          element={
            user && isEmailVerified ? (
              isAdmin ? (
                <Navigate to="/dashboard/admin" /> 
              ) : isApproved ? (
                <Navigate to="/dashboard/lawyer-profile" />
              ) : (
                <Navigate to="/approval-pending" />
              )
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/dashboard/lawyer-profile"
          element={
            user && isEmailVerified && isApproved && !isAdmin ? (
              <LawyerProfileManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/profile/:id" element={<LawyerPublicProfile />} />
        <Route path="/approval-pending" element={<ApprovalPending />} />
        <Route path="/dashboard/admin" element={<AdminDashboard />} />
        <Route path="/dashboard/admin/lawyer-review/:id" element={<LawyerReview />} />
        <Route path="/dashboard/admin/lawyer-review-later/:id" element={<LawyerReviewLater />} />
        <Route
        path="/edit-info"
        element={
          user && isEmailVerified && !isApproved ? (
            <ResendEditProfile />  
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      </Routes>
    </div>
  );
};

export default App;
