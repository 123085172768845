import React, { useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import NewUsers from "../components/NewUsers";
import Reviews from "../components/Reviews";

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState("newUsers");
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate("/login");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className="w-full min-h-screen flex flex-col items-center justify-start pt-20">
            <div className="w-11/12 max-w-7xl flex justify-between items-center mb-4">
                <div className="flex-1 flex justify-center space-x-4">
                    <button
                        className={`px-4 py-2 font-semibold border-b-2 ${activeTab === "newUsers" ? "border-blue-500 text-blue-500" : "border-transparent"}`}
                        onClick={() => setActiveTab("newUsers")}
                    >
                        Yeni Kullanıcılar
                    </button>
                    <button
                        className={`px-4 py-2 font-semibold border-b-2 ${activeTab === "reviews" ? "border-blue-500 text-blue-500" : "border-transparent"}`}
                        onClick={() => setActiveTab("reviews")}
                    >
                        İncelemeye Gönderilenler
                    </button>
                </div>

                <button
                    onClick={handleLogout}
                    className="px-4 py-2 bg-red-500 text-white font-semibold rounded hover:bg-red-600 transition duration-300"
                >
                    Çıkış Yap
                </button>
            </div>

            <div
                className="w-11/12 max-w-7xl flex-grow overflow-y-auto p-6 bg-white shadow-md rounded-lg"
                style={{ width: "90%" }}
            >
                {activeTab === "newUsers" ? <NewUsers /> : <Reviews />}
            </div>
        </div>
    );
};

export default AdminDashboard;
