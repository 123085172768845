import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, storage, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { expertiseOptions } from "../constants/expertiseOptions";
import InputField from "../components/InputField";
import TextAreaField from "../components/TextAreaField";
import FileField from "../components/FileField";
import CityDistrictSelect from "../components/CityDistrictSelect";
import { expertiseNumber, googleMeetingLink } from "../constants/appConstants";
import ConsentCheckboxes from "../components/ConsentCheckBoxes";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    website: "",
    city: "",
    district: "",
    address: "",
    barAssociation: "",
    barRegistrationNumber: "",
    googleMeetingLink: "",
    expertise: [],
    aboutMe: "",
    photo: null,
    baroCardPhoto: null,
  });

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [kvkkAccepted, setKvkkAccepted] = useState(false);

  const validateForm = () => {
    let formErrors = {};

    if (!formData.firstName.trim()) formErrors.firstName = "İsim zorunludur.";
    if (!formData.lastName.trim()) formErrors.lastName = "Soyisim zorunludur.";
    if (!formData.email.trim()) {
      formErrors.email = "Email zorunludur.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        formErrors.email = "Geçerli bir email adresi giriniz.";
      }
    }
    if (!formData.password.trim()) {
      formErrors.password = "Şifre zorunludur.";
    } else if (formData.password.length < 6) {
      formErrors.password = "Şifre en az 6 karakter olmalıdır.";
    }
    if (!formData.phone.trim()) {
      formErrors.phone = "Telefon numarası zorunludur.";
    } else {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(formData.phone)) {
        formErrors.phone =
          "Telefon numarası 10 haneli olmalı ve boşluk içermemelidir.";
      }
    }
    if (!formData.city.trim()) formErrors.city = "İl zorunludur.";
    if (!formData.district.trim()) formErrors.district = "İlçe zorunludur.";
    if (!formData.address.trim()) formErrors.address = "Adres zorunludur.";
    if (!formData.barAssociation.trim())
      formErrors.barAssociation = "Kayıtlı baro zorunludur.";
    if (!formData.barRegistrationNumber.trim())
      formErrors.barRegistrationNumber = "Baro sicil numarası zorunludur.";
    if (formData.expertise.length === 0)
      formErrors.expertise = "En az bir çalışma alanı seçilmelidir.";
    if (formData.expertise.length > expertiseNumber)
      formErrors.expertise = `En fazla ${expertiseNumber} çalışma alanı seçebilirsiniz.`;
    if (!formData.aboutMe.trim())
      formErrors.aboutMe = "Hakkımda yazısı zorunludur.";
    if (!formData.photo) formErrors.photo = "Fotoğraf yüklemek zorunludur.";
    if (!formData.baroCardPhoto) formErrors.baroCardPhoto = "Baro kimlik kartının resmi yüklemek zorunludur.";
    if (!formData.googleMeetingLink.trim())
      formErrors.googleMeetingLink = "Google Meeting Linki Zorunludur";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleInitialCapitalize = (e) => {
    const { name, value } = e.target;
    const capitalizedValue = value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setFormData({ ...formData, [name]: capitalizedValue });
    setErrors({ ...errors, [name]: "" });
  };

  const handleFileChange = (e, sizeLimitMB) => {
    const file = e.target.files[0];
    const sizeLimit = sizeLimitMB * 1024 * 1024;

    if (file) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        setErrors({ ...errors, [e.target.name]: "Sadece PNG, JPG, veya JPEG dosyaları yükleyebilirsiniz." });
        setFormData({ ...formData, [e.target.name]: null });
        return;
      }

      if (file.size > sizeLimit) {
        setErrors({ ...errors, [e.target.name]: `Dosya boyutu ${sizeLimitMB}MB'yi geçmemelidir.` });
        setFormData({ ...formData, [e.target.name]: null });
        return;
      }

      setErrors({ ...errors, [e.target.name]: "" });
      setFormData({ ...formData, [e.target.name]: file });
    }
  };

  const handleExpertiseSelect = (option) => {
    let updatedExpertise = [...formData.expertise];

    if (updatedExpertise.includes(option)) {
      updatedExpertise = updatedExpertise.filter((item) => item !== option);
    } else if (updatedExpertise.length < expertiseNumber) {
      updatedExpertise.push(option);
    }

    setFormData({ ...formData, expertise: updatedExpertise });
    setErrors({ ...errors, expertise: "" });
  };

  const handleRemoveExpertise = (option) => {
    setFormData({
      ...formData,
      expertise: formData.expertise.filter((item) => item !== option),
    });
  };

  const uploadFileToStorage = async (file, path) => {
    const fileRef = storageRef(storage, path);
    await uploadBytes(fileRef, file);
    return await getDownloadURL(fileRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    if (
      formData.googleMeetingLink &&
      !formData.googleMeetingLink.startsWith("http://") &&
      !formData.googleMeetingLink.startsWith("https://")
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        googleMeetingLink: `https://${prevFormData.googleMeetingLink}`,
      }));
    }

    if (validateForm()) {
      setLoading(true);

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );
        const user = userCredential.user;

        await sendEmailVerification(user);

        const profilePhotoURL = await uploadFileToStorage(
          formData.photo,
          `users/${user.uid}/userProfilePhoto`
        );

        const baroCardURL = await uploadFileToStorage(
          formData.baroCardPhoto,
          `users/${user.uid}/baroCardPhoto`
        );

        await setDoc(doc(db, "users", user.uid), {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          website: formData.website,
          city: formData.city,
          district: formData.district,
          address: formData.address,
          barAssociation: formData.barAssociation,
          barRegistrationNumber: formData.barRegistrationNumber,
          expertise: formData.expertise,
          aboutMe: formData.aboutMe,
          photoURL: profilePhotoURL,
          baroCardURL: baroCardURL,
          googleMeetingLink: formData.googleMeetingLink,
          isApproved: false,
          status: false,
          isReview: false,
          isResend: false
        });

        setSuccessMessage(
          "Kullanıcı başarıyla kaydedildi. Lütfen emailinizi doğrulayın."
        );

        setTimeout(() => {
          navigate("/");
        }, 3000);

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phone: "",
          website: "",
          city: "",
          district: "",
          address: "",
          barAssociation: "",
          barRegistrationNumber: "",
          expertise: [],
          aboutMe: "",
          photo: null,
          baroCardPhoto: null,
        });
      } catch (error) {
        let errorMessage = "Hata oluştu: ";

        if (error.code === "auth/email-already-in-use") {
          errorMessage += "Bu email adresi zaten kullanımda.";
        } else if (error.code === "auth/invalid-email") {
          errorMessage += "Geçersiz email adresi.";
        } else if (error.code === "auth/weak-password") {
          errorMessage += "Şifre çok zayıf.";
        } else {
          errorMessage += error.message;
        }

        setErrorMessage(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-28 my-10 p-8 bg-gray-100 shadow-md rounded-lg">
      <h1 className="text-4xl font-bold mb-8 text-center">Avukat Kayıt</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            label="İsim (*)"
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={handleInitialCapitalize}
            error={errors.firstName}
            placeholder="İsim"
            maxLength={40}
          />
          <InputField
            label="Soyisim (*)"
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={handleInitialCapitalize}
            error={errors.lastName}
            placeholder="Soyisim"
            maxLength={40}
          />
          <InputField
            label="Email (*)"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            placeholder="Email"
            maxLength={60}
          />
          <InputField
            label="Şifre (*)"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password}
            placeholder="Şifre"
            maxLength={30}
          />
          <InputField
            label="Telefon Numarası (*)"
            name="phone"
            type="text"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            placeholder="Telefon Numarası (5xxxxxxxxx)"
            maxLength={10}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          <InputField
            label="İnternet Sitesi"
            name="website"
            type="text"
            value={formData.website}
            onChange={handleChange}
            error={errors.website}
            placeholder="İnternet Sitesi"
            maxLength={200}
          />
        </div>
        <CityDistrictSelect
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          isSignUp={true}
        />
        <TextAreaField
          label="Adres (*)"
          name="address"
          value={formData.address}
          onChange={handleChange}
          error={errors.address}
          placeholder="Adres (Detay)"
          maxLength={250}
        />
        <InputField
          label="Kayıtlı Baro (*)"
          name="barAssociation"
          type="text"
          value={formData.barAssociation}
          onChange={handleChange}
          error={errors.barAssociation}
          placeholder="Kayıtlı Baro"
          maxLength={100}
        />
        <InputField
          label="Baro Sicil No (*)"
          name="barRegistrationNumber"
          type="text"
          value={formData.barRegistrationNumber}
          onChange={handleChange}
          error={errors.barRegistrationNumber}
          placeholder="Baro Sicil No"
          maxLength={40}
        />
        <div className="flex items-center space-x-2">
          <div className="flex-grow">
            <InputField
              label="Google Meeting Linki (*)"
              name="googleMeetingLink"
              type="text"
              value={formData.googleMeetingLink}
              onChange={handleChange}
              error={errors.googleMeetingLink}
              placeholder="Google Meeting Linki"
              maxLength={200}
            />
          </div>
          <button
            type="button"
            className="w-8 h-8 flex items-center justify-center bg-blue-500 text-white rounded-full hover:bg-blue-600 transition"
            onClick={() => window.open(googleMeetingLink, "_blank", "noopener,noreferrer")}
            aria-label="Info"
          >
            ?
          </button>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Çalışma Alanları (En fazla {expertiseNumber} alan seçebilirsiniz) (*)
          </label>
          <div className="flex flex-wrap gap-2 mb-4">
            {formData.expertise.map((option) => (
              <div
                key={option}
                className="flex items-center bg-gray-200 text-gray-700 px-3 py-1 rounded-full cursor-pointer"
                onClick={() => handleRemoveExpertise(option)}
              >
                {option}
                <span className="ml-2 text-red-500 hover:text-red-700">
                  &times;
                </span>
              </div>
            ))}
          </div>

          <div className="flex flex-wrap gap-2">
            {expertiseOptions
              .filter((option) => !formData.expertise.includes(option))
              .map((option) => (
                <div
                  key={option}
                  className="flex items-center bg-gray-100 hover:bg-gray-300 text-gray-700 px-3 py-1 rounded-full cursor-pointer border border-gray-300 transition duration-300"
                  onClick={() => handleExpertiseSelect(option)}
                  draggable
                  onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", option)
                  }
                  onDrop={(e) => {
                    e.preventDefault();
                    handleExpertiseSelect(e.dataTransfer.getData("text/plain"));
                  }}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {option}
                </div>
              ))}
          </div>
          {errors.expertise && (
            <p className="text-red-500 text-sm">{errors.expertise}</p>
          )}
        </div>
        <TextAreaField
          label="Hakkımda Yazısı (*)"
          name="aboutMe"
          value={formData.aboutMe}
          onChange={handleChange}
          error={errors.aboutMe}
          placeholder="Hakkımda Yazısı"
          rows={6}
          maxLength={1000}
        />
        <FileField
          label="Profil Fotoğrafı Yükleyin (*)"
          name="photo"
          onChange={handleFileChange}
          error={errors.photo}
        />
        <FileField
          label="Baro Kimlik Kartının Resmi (*)"
          name="baroCardPhoto"
          onChange={handleFileChange}
          error={errors.baroCardPhoto}
        />

        <p className="text-sm text-gray-700 mt-2">
          (*) Bu alanların doldurulması zorunludur.
        </p>

        <ConsentCheckboxes
          termsAccepted={termsAccepted}
          kvkkAccepted={kvkkAccepted}
          setTermsAccepted={setTermsAccepted}
          setKvkkAccepted={setKvkkAccepted}
        />

        <button
          type="submit"
          className={`w-full border font-bold border-gray-600 text-gray-600 px-4 py-2 rounded-lg ${termsAccepted && kvkkAccepted ? "hover:bg-gray-600 hover:text-white" : "cursor-not-allowed opacity-50"
            } transition duration-300 ease-in-out`}
          disabled={!termsAccepted || !kvkkAccepted || loading}
        >
          {loading ? "Kaydediliyor..." : "Kaydet"}
        </button>
      </form>
      {successMessage && (
        <div className="mt-6 p-4 bg-green-100 text-green-700 rounded">
          {successMessage}
        </div>
      )}

      {errorMessage && (
        <div className="mt-6 p-4 bg-red-100 text-red-700 rounded">
          {errorMessage}
        </div>
      )}
      <div className="mt-8 text-center">
        <p className="text-gray-600">
          Zaten bir hesabınız var mı?{" "}
          <span
            onClick={() => navigate("/login")}
            className="text-blue-600 hover:underline cursor-pointer"
          >
            Giriş Yap
          </span>
        </p>
      </div>
    </div>
  );
};

export default Signup;
