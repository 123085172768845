import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/avukatlogo.png";

const Navbar = ({ user }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  return (
    <nav className="absolute top-0 left-0 w-full z-10">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center ml-16">
            {!isHomePage && (
              <Link to="/">
                <img src={logo} alt="Logo" className="absolute left-8 h-48 w-auto" />
              </Link>

            )}
          </div>
          <div className="hidden md:flex items-center space-x-4">
            {user ? (
              <div className="flex items-center space-x-4">
                <Link to="/dashboard/lawyer-profile">
                  <button className="border font-semibold border-gray-800 text-gray-800 px-4 py-2  rounded-lg hover:bg-gray-800 hover:text-white transition duration-300 ease-in-out">
                    {user.firstName} {user.lastName}
                  </button>
                </Link>
              </div>
            ) : (
              <Link to="/login">
                <button className="border font-semibold border-gray-800 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-800 hover:text-white transition duration-300 ease-in-out">
                  Avukat Giriş
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
