export const expertiseOptions = [
  "Aile",
  "Alacak",
  "Arabuluculuk",
  "Arsa",
  "Askerlik",
  "Banka",
  "Basın",
  "Bilişim",
  "Boşanma",
  "Ceza",
  "Dernek",
  "Devralma",
  "Gayrimenkul",
  "Göçmenlik",
  "Hayvan Hakları",
  "İcra",
  "İdare",
  "İflas",
  "İnşaat",
  "İş",
  "Kamu",
  "Kaza",
  "Kredi",
  "Marka",
  "Medya",
  "Miras",
  "Patent",
  "Sağlık",
  "Satış",
  "Sigorta",
  "Şirket",
  "Sosyal Güvenlik",
  "Tapu",
  "Tazminat",
  "Ticaret",
  "Trafik",
  "Turizm",
  "Tüketici Hakları",
  "Uluslararası",
  "Uyuşmazlık",
  "Uyuşturucu",
  "Vakıf",
  "Velayet",
  "Vergi",
];