import React from "react";
import { cities, districts } from "../constants/districts";

const CityDistrictSelect = ({ formData, setFormData, errors, setErrors, disabled = false, isSignUp = false }) => {
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setFormData({ ...formData, city: selectedCity, district: "" });
    setErrors({ ...errors, city: "", district: "" });
  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setFormData({ ...formData, district: selectedDistrict });
    setErrors({ ...errors, district: "" });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">
          İl {isSignUp ? "(*)" : ""}
        </label>
        <select
          name="city"
          id="city"
          value={formData.city}
          onChange={handleCityChange}
          className={`p-3 border w-full rounded shadow-sm ${disabled ? "bg-gray-100" : ""} ${errors.city ? "border-red-500" : "border-gray-300"}`}
          disabled={disabled}

        >
          <option value="">Şehir Seçin</option>
          {cities.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
        {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
      </div>
      <div>
        <label htmlFor="district" className="block text-sm font-medium text-gray-700 mb-1">
          İlçe {isSignUp ? "(*)" : ""}
        </label>
        <select
          name="district"
          id="district"
          value={formData.district}
          onChange={handleDistrictChange}
          disabled={disabled || !formData.city}
          className={`p-3 border w-full rounded shadow-sm ${disabled || !formData.city ? "bg-gray-100" : ""} ${errors.district ? "border-red-500" : "border-gray-300"}`}
        >
          <option value="">İlçe Seçin</option>
          {formData.city &&
            districts[formData.city]?.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
        </select>
        {errors.district && (
          <p className="text-red-500 text-sm">{errors.district}</p>
        )}
      </div>
    </div>
  );
};

export default CityDistrictSelect;
