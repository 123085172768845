const TextAreaField = ({ label, name, value, onChange, error, placeholder, rows = 2, maxLength, disabled = false,
}) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={name}>
      {label}
    </label>
    <textarea
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      maxLength={maxLength}
      className={`w-full p-3 border ${error ? "border-red-500" : "border-gray-300"} rounded shadow-sm`}
      disabled={disabled}
    />
    {error && <p className="text-red-500 text-sm">{error}</p>}
  </div>
);

export default TextAreaField;
