import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirected) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [redirected]);

  const validateForm = () => {
    let formErrors = {};

    if (!formData.email.trim()) {
      formErrors.email = "Email zorunludur.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        formErrors.email = "Geçerli bir email adresi giriniz.";
      }
    }

    if (!isPasswordReset && !formData.password.trim()) {
      formErrors.password = "Şifre zorunludur.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setVerificationSent(false);
    setShowResendButton(false);

    if (validateForm()) {
      setLoading(true);
      try {
        if (isPasswordReset) {
          await sendPasswordResetEmail(auth, formData.email);
          setResetMessage(
            "Şifre sıfırlama bağlantısı email adresinize gönderildi."
          );
        } else {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            formData.email,
            formData.password
          );
          const user = userCredential.user;

          if (user.emailVerified) {
            navigate("/dashboard/lawyer-profile");
            setRedirected(true);
          } else {
            setErrorMessage(
              "Email adresiniz henüz doğrulanmamış. Lütfen emailinize gelen doğrulama bağlantısını kullanın."
            );
            setShowResendButton(true);
            await auth.signOut();
          }
        }
      } catch (error) {
        let message = "Giriş hatası: ";
        switch (error.code) {
          case "auth/invalid-credential":
            message = "Geçersiz giriş bilgisi, lütfen tekrar deneyin.";
            break;
          default:
            message += error.message;
        }
        setErrorMessage(message);
        await auth.signOut();
        setFormData({ email: "", password: "" });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleResendVerification = async () => {
    if (!formData.email) {
      setErrorMessage("Lütfen önce bir email girin.");
      return;
    }
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      await sendEmailVerification(user);
      setVerificationSent(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(`Doğrulama emaili gönderilemedi: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    setErrorMessage("");
    setResetMessage("");
    setVerificationSent(false);
    setShowResendButton(false);
  };

  const handlePasswordResetClick = () => {
    setIsPasswordReset(!isPasswordReset);
    setFormData({ ...formData, password: "" });
    setErrors({});
    setErrorMessage("");
    setResetMessage("");
    setVerificationSent(false);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="max-w-md w-full p-8 bg-gray-100 shadow-md rounded-lg">
        <h1 className="text-4xl font-bold mb-8 text-center">
          {isPasswordReset ? "Şifre Sıfırlama" : "Avukat Giriş"}
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className={`p-3 border w-full ${errors.email ? "border-red-500" : "border-gray-300"
                } rounded shadow-sm`}
              autoComplete="email"
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
          </div>
          {!isPasswordReset && (
            <div>
              <input
                type="password"
                name="password"
                placeholder="Şifre"
                value={formData.password}
                onChange={handleChange}
                className={`p-3 border w-full ${errors.password ? "border-red-500" : "border-gray-300"
                  } rounded shadow-sm`}
              />
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
            </div>
          )}

          <button
            type="submit"
            className="w-full border font-bold border-gray-600 text-gray-600 px-4 py-2 rounded-lg hover:bg-gray-600 hover:text-white transition duration-300 ease-in-out"
          >
            {loading
              ? "İşlem Yapılıyor..."
              : isPasswordReset
                ? "Şifremi Sıfırla"
                : "Giriş Yap"}
          </button>
        </form>

        {errorMessage && (
          <div className="mt-6 p-4 bg-red-100 text-red-700 rounded">
            {errorMessage}
            {showResendButton && (
              <button
                onClick={handleResendVerification}
                className="ml-2 text-blue-500 hover:underline"
              >
                Doğrulama emailini tekrar gönder
              </button>
            )}
          </div>
        )}

        {resetMessage && (
          <div className="mt-6 p-4 bg-green-100 text-green-700 rounded">
            {resetMessage}
          </div>
        )}

        {verificationSent && (
          <div className="mt-6 p-4 bg-green-100 text-green-700 rounded">
            Doğrulama emaili tekrar gönderildi. Lütfen emailinizi kontrol edin.
          </div>
        )}

        <div className="mt-4 text-center">
          <button
            onClick={handlePasswordResetClick}
            className="text-blue-500 hover:underline"
          >
            {isPasswordReset ? "Giriş Yap" : "Şifremi Unuttum?"}
          </button>
          {!isPasswordReset && (
            <>
              <span className="mx-2">|</span>
              <a href="/signup" className="text-blue-500 hover:underline">
                Yeni Avukat Kayıt
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
