import React, { useState } from 'react';

const WorkAreaFilter = ({ selectedExpertise, setSelectedExpertise }) => {
  const workAreas = [
    "Aile",
  "Alacak",
  "Arabuluculuk",
  "Arsa",
  "Askerlik",
  "Banka",
  "Basın",
  "Bilişim",
  "Boşanma",
  "Ceza",
  "Dernek",
  "Devralma",
  "Gayrimenkul",
  "Göçmenlik",
  "Hayvan Hakları",
  "İcra",
  "İdare",
  "İflas",
  "İnşaat",
  "İş",
  "Kamu",
  "Kaza",
  "Kredi",
  "Marka",
  "Medya",
  "Miras",
  "Patent",
  "Sağlık",
  "Satış",
  "Sigorta",
  "Şirket",
  "Sosyal Güvenlik",
  "Tapu",
  "Tazminat",
  "Ticaret",
  "Trafik",
  "Turizm",
  "Tüketici Hakları",
  "Uluslararası",
  "Uyuşmazlık",
  "Uyuşturucu",
  "Vakıf",
  "Velayet",
  "Vergi",
  ];

  const handleExpertiseChange = (event) => {
    const value = event.target.value;

    if (selectedExpertise.includes(value)) {
      setSelectedExpertise(selectedExpertise.filter(item => item !== value));
    } else if (selectedExpertise.length < 2) {
      setSelectedExpertise([...selectedExpertise, value]);
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Çalışma Alanı ile Filtrele:</label>
      <div className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        {workAreas.map((area, index) => (
          <label key={index} className="inline-flex items-center mr-2">
            <input
              type="checkbox"
              value={area}
              onChange={handleExpertiseChange}
              checked={selectedExpertise.includes(area)}
              className="form-checkbox h-5 w-5 text-indigo-600"
            />
            <span className="ml-2">{area}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default WorkAreaFilter;
