import React from 'react';

const KVKK = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-4xl font-bold mb-6">KVKK Metni</h1>
                <p>Here you can put your KVKK content...</p>
            </div>
        </div>
    );
};

export default KVKK;
