import React, { useEffect, useRef } from "react";
import homepageImage from "../assets/homepageimage.png";
import logo from "../assets/avukatlogo.png";
import { Link as ScrollLink } from "react-scroll";
import gsap from "gsap";

const HeroSection = () => {
  const heroRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    if (heroRef.current && textRef.current) {
      gsap.fromTo(
        heroRef.current,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 3.5, ease: "power3.out" }
      );

      gsap.fromTo(
        textRef.current,
        { opacity: 0, x: "100vw" },
        { opacity: 1, x: 0, duration: 2, ease: "power3.out", delay: 0 }
      );
    }
  }, []);

  return (
    <div
      ref={heroRef}
      className="w-full h-screen flex flex-col justify-between bg-gray-100 relative"
    >
      <div className="absolute top-8 left-12">
        <img src={logo} alt="Logo" className="h-72 w-auto" />
      </div>

      <div className="flex-1 flex items-center justify-between">
        <div className="flex-1 pl-12">
          <h1
            className="text-7xl font-bold text-gray-700 mb-4"
            style={{ fontFamily: "'EB Garamond', serif" }}
          >
            ANINDA HUKUKİ DESTEK
          </h1>
          <p className="text-2xl text-gray-600 mb-8">
            Sitemizdeki uzman avukatlar ile saniyeler içerisinde görüntülü görüşün
          </p>
          <ScrollLink
            to="lawyer-connect-section"
            smooth={true}
            duration={500}
            className="inline-block bg-[#B89867] text-white font-semibold text-lg py-3 px-8 rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105"
          >
            Avukatlarla Görüşmeye Başla
          </ScrollLink>
        </div>

        <div className="flex-1 flex items-center justify-end pr-12">
          <img
            src={homepageImage}
            alt="Anasayfa Resmi"
            className="h-auto max-w-m md:max-w-xl lg:max-w-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
