import React from "react";
import HeroSection from "../components/HeroSection";
import LawyerConnectSection from "../components/LawyerConnectSection";
import WhySection from "../components/WhySection";


const Home = () => {
  return (
    <div>
      <HeroSection />
      <WhySection />
      <LawyerConnectSection />
    </div>
  );
};

export default Home;
