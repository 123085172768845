import React from "react";

const AnimatedArrows = () => {
  return (
    <div className="flex flex-col items-center">
      <h2 className="text-7xl font-bold mb-4">Avukatlara Bağlanın</h2>
      <div className="flex mt-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-28 w-28 text-yellow-500 animate-pulse"
          fill="none"
          viewBox="0 0 32 32"
          stroke="currentColor"
          strokeWidth={2}
          style={{ animationDelay: "0s", animationDuration: "0.6s" }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-28 w-28 text-yellow-500 animate-pulse"
          fill="none"
          viewBox="0 0 32 32"
          stroke="currentColor"
          strokeWidth={2}
          style={{ animationDelay: "0.2s", animationDuration: "0.6s" }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-28 w-28 text-yellow-500 animate-pulse"
          fill="none"
          viewBox="0 0 32 32"
          stroke="currentColor"
          strokeWidth={2}
          style={{ animationDelay: "0.4s", animationDuration: "0.6s" }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-28 w-28 text-yellow-500 animate-pulse"
          fill="none"
          viewBox="0 0 32 32"
          stroke="currentColor"
          strokeWidth={2}
          style={{ animationDelay: "0.6s", animationDuration: "0.6s" }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-28 w-28 text-yellow-500 animate-pulse"
          fill="none"
          viewBox="0 0 32 32"
          stroke="currentColor"
          strokeWidth={2}
          style={{ animationDelay: "0.8s", animationDuration: "0.6s" }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </div>
  );
};

export default AnimatedArrows;
