import React, { useEffect, useState, useCallback } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";  // Firestore functions
import { db } from "../firebase";  // Import Firestore (db)
/* import { useNavigate } from "react-router-dom"; */
import AnimatedArrows from "./animatedArrows";
import { FaLock, FaUnlock, FaSync } from "react-icons/fa";
import WorkAreaFilter from './WorkAreaFilter';
import { FaFilter } from 'react-icons/fa';

const LawyerConnectSection = () => {
  const [lawyers, setLawyers] = useState([]);
  const [lockedLawyers, setLockedLawyers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoveredLawyer, setHoveredLawyer] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const hoverDelay = 200;
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filteredLawyers, setFilteredLawyers] = useState([]);
  const [filterCity, setFilterCity] = useState("");
  const [noResults, setNoResults] = useState(false);
  // const navigate = useNavigate();

  const fetchLawyers = useCallback(async () => {
    const usersCollection = collection(db, "users");
    try {
      const snapshot = await getDocs(usersCollection);
      if (!snapshot.empty) {
        const fetchedLawyers = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));

        const shuffledLawyers = fetchedLawyers.sort(() => Math.random() - 0.5);
        const onlineLawyers = shuffledLawyers.filter(
          (lawyer) => lawyer.status === true // true olanlar online
        );

        const newLawyers = onlineLawyers.filter(
          (lawyer) => !lockedLawyers.find((l) => l.id === lawyer.id)
        );

        setLawyers([...lockedLawyers, ...newLawyers.slice(0, 10)]);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [lockedLawyers]);

  useEffect(() => {
    fetchLawyers();
    // eslint-disable-next-line
  }, []); // Burada sadece component mount olduğunda çalıştırıyoruz

  useEffect(() => {
    let intervalId;
    let countdown;

    if (isTimerActive) {
      intervalId = setInterval(() => {
        setTimeRemaining(60);
        fetchLawyers();  // Refresh data every 60 seconds
      }, 60000);

      countdown = setInterval(() => {
        setTimeRemaining((prev) => (prev > 0 ? prev - 1 : 60));
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(countdown);
    };
  }, [isTimerActive, fetchLawyers]);

  const handleMouseEnter = (lawyer) => {
    setIsHovering(true);
    setHoveredLawyer(lawyer);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!isHovering) {
        setHoveredLawyer(null);
      }
    }, hoverDelay);
    setIsHovering(false);
  };

  const handleCardClick = (lawyer) => {
    if (lawyer.googleMeetingLink && lawyer.status === true) { // true olanlar online
      window.open(lawyer.googleMeetingLink, "_blank");
    }
  };

  /* const handleRefreshClick = () => {
    fetchLawyers();
  }; */

  const handleLockClick = (e, lawyer) => {
    e.stopPropagation();  // Prevent the click event from propagating
    setLockedLawyers((prevLockedLawyers) => {
      if (prevLockedLawyers.find((l) => l.id === lawyer.id)) {
        return prevLockedLawyers.filter((l) => l.id !== lawyer.id);
      } else {
        return [...prevLockedLawyers, lawyer];
      }
    });
  };

  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  const handleFilter = async () => {
    const usersCollection = collection(db, "users");

    try {
      const snapshot = await getDocs(usersCollection);
      if (!snapshot.empty) {
        const allLawyers = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));

        let filtered = allLawyers;

        // İsim filtresi
        if (filterName.trim() !== "") {
          filtered = filtered.filter((lawyer) =>
            `${lawyer.firstName} ${lawyer.lastName}`.toLowerCase().includes(filterName.toLowerCase())
          );
        }

        // Şehir filtresi
        if (filterCity.trim() !== "") {
          filtered = filtered.filter((lawyer) =>
            lawyer.city.toLowerCase().includes(filterCity.toLowerCase())
          );
        }

        // Çalışma alanı filtresi
        if (selectedExpertise.length > 0) {
          filtered = filtered.filter((lawyer) =>
            selectedExpertise.every(expertise => lawyer.expertise.includes(expertise))
          );
        }

        if (filtered.length > 0) {
          setFilteredLawyers(filtered);
          setNoResults(false); // Sonuçlar bulunduğunda false yapın
        } else {
          setFilteredLawyers([]);  // Kriterlere uyan avukat yoksa boş liste ayarla
          setNoResults(true); // Sonuç bulunamadıysa true yapın
        }

        setShowDropdown(false);  // Dropdown menüyü kapat
      } else {
        console.log("No data available");
        setFilteredLawyers([]);
        setNoResults(true); // Sonuç bulunamadıysa true yapın
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setFilteredLawyers([]);
      setNoResults(true); // Hata olduğunda sonuç bulunamadı olarak ayarla
    }
  };


  return (
    <div
      id="lawyer-connect-section"
      className="flex min-h-screen bg-gray-100 p-0"
    >
      <div
        className="flex-[2] flex justify-center items-center relative"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={handleMouseLeave}
      >
        {hoveredLawyer ? (
          <div className="relative max-w-3xl mx-auto p-8 bg-white shadow-md rounded-lg h-[700px] w-full overflow-y-scroll scrollbar-hide">
            <div className="flex items-center">
              <img
                src={hoveredLawyer.photoURL}
                alt={`${hoveredLawyer.firstName} ${hoveredLawyer.lastName}`}
                className="h-20 w-20 rounded-full object-cover mr-4"
              />
              <div>
                <h1 className="text-2xl font-bold">{`${hoveredLawyer.firstName} ${hoveredLawyer.lastName}`}</h1>
                <p className="text-gray-600">
                  {hoveredLawyer.city}, {hoveredLawyer.district}
                </p>
                <p
                  className={`text-lg ${hoveredLawyer.status === true
                    ? "text-green-500 animate-pulse"
                    : "text-gray-500"
                    }`}
                >
                  {hoveredLawyer.status === true ? "Bağlan" : "Çevrim dışı"} {/* true olanlar online */}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-1">Çalışma Alanları</h2>
              <div className="flex flex-wrap gap-2">
                {Array.isArray(hoveredLawyer.expertise) ? (
                  hoveredLawyer.expertise.map((item, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm border border-gray-300"
                    >
                      {item}
                    </span>
                  ))
                ) : (
                  <span className="text-gray-500 text-sm">
                    Çalışma alanı bulunmuyor
                  </span>
                )}
              </div>
            </div>

            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-1">Hakkımda</h2>
              <div className="text-gray-700 mb-2">
                {hoveredLawyer.aboutMe.split("\n").map((paragraph, index) => (
                  <p key={index} className="mb-4">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-1">İletişim</h2>
              <p className="text-gray-700 mb-2">
                <span className="font-bold">Email:</span> {hoveredLawyer.email}
              </p>

              <p className="text-gray-700 mb-2">
                <span className="font-bold">Telefon:</span>{" "}
                {hoveredLawyer.phone}
              </p>
              {hoveredLawyer.website && (
                <p className="text-gray-700 mb-2">
                  <span className="font-bold">Web Sitesi:</span>{" "}
                  <a
                    href={`${hoveredLawyer.website.startsWith("http")
                      ? hoveredLawyer.website
                      : `https://${hoveredLawyer.website}`
                      }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    {hoveredLawyer.website}
                  </a>
                </p>
              )}
              <p className="text-gray-700 mb-2">
                <span className="font-bold">Adres:</span>{" "}
                {hoveredLawyer.address}
              </p>
            </div>
            {hoveredLawyer.status === true && (
              <button
                className="absolute top-6 right-6 h-14 w-44 text-white bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 font-semibold text-lg px-6 py-3 flex items-center justify-center"
                onClick={() => handleCardClick(hoveredLawyer)}
              >
                Hemen Görüş
              </button>
            )}
          </div>
        ) : (
          <AnimatedArrows />
        )}
      </div>
      <div className="flex justify-center items-center mb-4">

        <div className="flex flex-col items-center mr-5">
          <p className="text-m font-bold text-gray-500">Avukatların Yenilenmesine</p>
          <div className="flex justify-center items-center w-24 h-24 bg-gradient-to-r from-[#B89867] to-[#8C6B3E] text-white text-3xl font-bold rounded-full shadow-lg">
            {timeRemaining}
          </div>

          <p className="text-m font-bold text-gray-500 mt-2">Saniye</p>
        </div>
      </div>
      <div className="flex-[1] flex flex-col justify-center items-end p-0">
        <div className="flex space-x-2 mb-4">
          <button
            className="mb-4 mr-3 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300 flex items-center justify-center"
            onClick={() => {
              setShowDropdown(!showDropdown);
              setIsTimerActive(false);  // Timer'ı durdur
            }}
          >
            <FaFilter className="mr-2" size={18} /> {/* Filtreleme ikonu */}
            Filtreleme
          </button>
          {showDropdown && (
            <div className="absolute mt-2 bg-white shadow-lg rounded-lg z-50 p-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">İsim ile Filtrele:</label>
                <input
                  type="text"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="İsim girin"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Şehir ile Filtrele:</label>
                <input
                  type="text"
                  value={filterCity}
                  onChange={(e) => setFilterCity(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Şehir girin"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Çalışma Alanı ile Filtrele:</label>
                <WorkAreaFilter
                  selectedExpertise={selectedExpertise}
                  setSelectedExpertise={setSelectedExpertise}
                />
              </div>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
                onClick={handleFilter}
              >
                Filtrele
              </button>
              <button
                className="px-4 py-2 ml-4 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
                onClick={() => setShowDropdown(false)}  // Dropdown menüyü kapat
              >
                Kapat
              </button>

            </div>
          )}

          <button
            className="mb-4 mr-3 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300 flex items-center justify-center"
            onClick={() => {
              setFilteredLawyers([]);
              setFilterName("");
              setFilterCity("");
              setSelectedExpertise([]);
              fetchLawyers();  // Avukatları yeniden getir
              setIsTimerActive(true);  // Timer'ı yeniden başlat
            }}
          >
            <FaSync className="mr-2" size={18} /> {/* Yenile ikonu */}
            Yenile
          </button>
        </div>
        <div className="w-full max-h-[800px] overflow-y-scroll scrollbar-hide">
          {noResults ? (
            <div className="text-center text-gray-500 mt-10">
              Aradığınız kriterlere uygun avukat bulunamadı.
            </div>
          ) : (
            (filteredLawyers.length > 0 ? filteredLawyers : lawyers).map((lawyer, index) => {
              const isLocked = lockedLawyers.some((l) => l.id === lawyer.id);
              return (
                <div
                  key={index}
                  className={`relative w-full max-w-lg bg-white shadow-lg rounded-lg overflow-hidden mb-6 flex items-center p-6 h-40 hover:cursor-pointer hover:bg-gray-100 ${isLocked
                    ? "bg-yellow-100 hover:cursor-pointer hover:bg-yellow-200"
                    : ""
                    }`}
                  onClick={() => handleCardClick(lawyer)} // Kartın tamamına tıklanabilirlik ekliyoruz
                  onMouseEnter={() => handleMouseEnter(lawyer)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className={`absolute top-4 right-4 h-5 w-5 rounded-full ${lawyer.status === true ? "bg-green-500" : "bg-red-500"
                      }`}
                  ></div>

                  <img
                    src={lawyer.photoURL}
                    alt={`${lawyer.firstName} ${lawyer.lastName}`}
                    className="h-20 w-20 rounded-full object-cover"
                  />

                  <div className="ml-4 flex-1">
                    <h3 className="text-lg font-semibold absolute top-3 left-28">
                      {lawyer.firstName} {lawyer.lastName}
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {Array.isArray(lawyer.expertise) ? (
                        lawyer.expertise.map((item, index) => (
                          <span
                            key={index}
                            className="bg-gray-200 text-gray-700 px-2 py-0.5 rounded-full text-xs border border-gray-300 "
                          >
                            {item}
                          </span>
                        ))
                      ) : (
                        <span className="text-gray-500 text-sm">
                          Çalışma alanı bulunmuyor
                        </span>
                      )}
                    </div>

                    <div className="flex items-center justify-between ">
                      <p
                        className={`text-xl absolute bottom-2  ${lawyer.status === true
                          ? "text-green-500 animate-pulse"
                          : "text-gray-500"
                          }`}
                      >
                        Bağlan
                      </p>
                      <button
                        className={`absolute bottom-2 right-4 px-3 py-1.5 text-white rounded-lg ${isLocked
                          ? "bg-black hover:bg-gray-800"
                          : "bg-gray-500 hover:bg-gray-600"
                          } transition duration-300 flex items-center justify-center`}
                        onClick={(e) => handleLockClick(e, lawyer)}
                      >
                        {isLocked ? (
                          <FaLock className="text-white" size={14} />
                        ) : (
                          <FaUnlock className="text-white" size={14} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>



      </div>
    </div>
  );
};

export default LawyerConnectSection;
