import React from 'react';

const ConsentCheckboxes = ({ termsAccepted, kvkkAccepted, setTermsAccepted, setKvkkAccepted }) => {
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div className="space-y-4">
            <div>
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                    />
                    <span>
                        <strong
                            onClick={() => openInNewTab("/kullanici-sozlesmesi")}
                            className="cursor-pointer"
                        >
                            Kullanici sozlesmesi
                        </strong>{" "}
                        onayliyorum.
                    </span>
                </label>
            </div>
            <div>
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={kvkkAccepted}
                        onChange={() => setKvkkAccepted(!kvkkAccepted)}
                    />
                    <span>
                        <strong onClick={() => openInNewTab("/kvkk")} className="cursor-pointer">
                            KVKK metnini
                        </strong>{" "}
                        onayliyorum.
                    </span>
                </label>
            </div>
        </div>
    );
};

export default ConsentCheckboxes;
