import React from "react";

const UploadModal = ({
  photoPreview,
  handleFileChange,
  handlePhotoUpdate,
  loading,
  uploadProgress,
  setShowPhotoUploader,
}) => {
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-xl font-bold mb-4">Fotoğraf Yükle</h2>
        {photoPreview && (
          <img
            src={photoPreview}
            alt="Önizleme"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
        )}
        <input
          type="file"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border cursor-pointer shadow-sm mb-4"
        />
        {loading && (
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        )}
        <button
          type="button"
          onClick={handlePhotoUpdate}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
          disabled={loading}
        >
          Yükle
        </button>
        <button
          type="button"
          onClick={() => setShowPhotoUploader(false)}
          className="ml-4 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition duration-300"
        >
          İptal
        </button>
      </div>
    </div>
  );
};

export default UploadModal;
