import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const ApprovalPending = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate("/login");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const handleEditInfo = () => {
        navigate("/edit-info");
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                try {
                    const userDocRef = doc(db, "users", currentUser.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        setUserData(userDoc.data());
                    }
                } catch (error) {
                    console.error("Error fetching user data: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (loading) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <div className="w-full min-h-screen flex flex-col items-center justify-start pt-20">
            <div className="w-full flex justify-end px-4">
                <button
                    onClick={handleLogout}
                    className="mt-4 px-4 py-2 bg-red-500 text-white font-semibold rounded hover:bg-red-600 transition duration-300"
                >
                    Çıkış Yap
                </button>
            </div>

            <div className="flex items-center justify-center flex-grow">
                {userData?.isResend && userData?.reviewText ? (
                    <div className="max-w-md bg-white shadow-md rounded-lg p-6">
                        <h1 className="text-xl font-semibold mb-4">
                            İncelemeniz sırasında sorun bulundu:
                        </h1>
                        <p className="text-gray-700 mb-4">{userData.reviewText}</p>
                        <button
                            onClick={handleEditInfo}
                            className="mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300"
                        >
                            Bilgileri Düzenle
                        </button>
                    </div>
                ) : (
                    <h1 className="text-center text-xl font-semibold">
                        Başvurunuz inceleniyor. Beklediğiniz için teşekkür ederiz.
                    </h1>
                )}
            </div>
        </div>
    );
};

export default ApprovalPending;
