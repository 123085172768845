import React, { useEffect, useRef } from 'react';
import { FaCheckCircle, FaClock, FaUserShield, FaSearch } from 'react-icons/fa';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";

gsap.registerPlugin(ScrollTrigger);

const WhySection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      sectionRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 80%",
        },
      }
    );
  }, []);

  return (
    <div ref={sectionRef} className="flex flex-col md:flex-row justify-between items-center bg-gray-100 py-12 px-6 pb-48">

      <div className="md:w-1/2 text-left mb-6 ml-24 md:mb-0">
        <h2 className="text-4xl font-bold text-gray-700 mb-4">
          Neden "Avukat Bul"?
        </h2>
        <ul className="space-y-4 text-lg text-gray-600">
          <li className="flex items-start">
            <FaClock className="text-[#B89867] mr-3 mt-1" />
            <span>Hızlı ve güvenilir avukat bulma imkanı</span>
          </li>
          <li className="flex items-start">
            <FaUserShield className="text-[#B89867] mr-3 mt-1" />
            <span>Doğrudan avukatlar ile anında bağlantı kurun</span>
          </li>
          <li className="flex items-start">
            <FaSearch className="text-[#B89867] mr-3 mt-1" />
            <span>Geniş bir avukat yelpazesi arasından seçim yapın</span>
          </li>
          <li className="flex items-start">
            <FaCheckCircle className="text-[#B89867] mr-3 mt-1" />
            <span>Gizlilik ve güvenlik garantisi</span>
          </li>
        </ul>
      </div>

      <div className="md:w-1/2 flex justify-center items-center">
        <img src={image1} alt="Happy lawyer talking to a client" className="w-48 h-auto" />
        <img src={image2} alt="Legal advice session" className="w-48 h-auto transform -translate-y-6 mx-6" />
        <img src={image3} alt="Professional law firm building" className="w-48 h-auto" />
      </div>
    </div>
  );
};

export default WhySection;
