import { supportedImageTypes, defaultSizeLimit } from "../constants/appConstants";


const FileField = ({ label, name, onChange, error, accept = supportedImageTypes, sizeLimit = defaultSizeLimit }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={name}>
      {label}
    </label>
    <input
      type="file"
      name={name}
      id={name}
      accept={accept}
      onChange={(e) => onChange(e, sizeLimit)}
      className={`block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border ${error ? "border-red-500" : "border-gray-300"
        } cursor-pointer shadow-sm`}
    />
    {error && <p className="text-red-500 text-sm">{error}</p>}
  </div>
);


export default FileField;
