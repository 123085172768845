import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const LawyerReview = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const lawyer = location.state?.lawyer;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isResendModalOpen, setIsResendModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reviewText, setReviewText] = useState("");

    if (!lawyer) {
        return <p className="text-center text-xl font-bold">Avukat Bulunmadı.</p>;
    }

    const handleApprove = async () => {
        setLoading(true);
        try {
            const userDocRef = doc(db, "users", lawyer.id);
            await updateDoc(userDocRef, {
                isApproved: true,
            });
            navigate(-1);
        } catch (error) {
            console.error("Error approving user: ", error);
            alert("Error approving user.");
        } finally {
            setLoading(false);
        }
    };

    const handleResend = async () => {
        setLoading(true);
        try {
            const userDocRef = doc(db, "users", lawyer.id);
            await updateDoc(userDocRef, {
                reviewText: reviewText,
                isResend: true,
            });
            setIsResendModalOpen(false);
            navigate(-1);
        } catch (error) {
            console.error("Error sending back for review: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-3xl mx-auto mt-28 p-8 bg-white shadow-md rounded-lg relative">
            <div className="absolute top-4 right-4 flex space-x-4">
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                    onClick={handleApprove}
                    disabled={loading}
                >
                    {loading ? "Onaylanıyor..." : "Onayla"}
                </button>
                <button
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
                    onClick={() => setIsResendModalOpen(true)}
                >
                    Geri Gönder
                </button>
            </div>

            <h1 className="text-3xl font-bold mb-6">{`${lawyer.firstName} ${lawyer.lastName}`}</h1>

            {lawyer.photoURL && (
                <div className="mb-6">
                    <img
                        src={lawyer.photoURL}
                        alt={`${lawyer.firstName} ${lawyer.lastName}`}
                        className="h-20 w-20 rounded-full mb-4"
                    />
                </div>
            )}

            <p className="text-gray-700"><strong>Email:</strong> {lawyer.email}</p>
            <p className="text-gray-700"><strong>Telefon:</strong> {lawyer.phone}</p>
            {lawyer.website && (
                <p className="text-gray-700">
                    <strong>Web Sitesi:</strong>{" "}
                    <a
                        href={`${lawyer.website.startsWith("http") ? lawyer.website : `https://${lawyer.website}`}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                    >
                        {lawyer.website}
                    </a>
                </p>
            )}

            <p className="text-gray-700">
                <strong>Şehir:</strong> {lawyer.city}, <strong>İlçe:</strong> {lawyer.district}
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">Adres</h2>
            <p className="text-gray-700 mb-4">{lawyer.address}</p>

            <p className="text-gray-700"><strong>Kayıtlı Baro:</strong> {lawyer.barAssociation}</p>
            <p className="text-gray-700"><strong>Baro Sicil No:</strong> {lawyer.barRegistrationNumber}</p>

            <h2 className="text-xl font-semibold mt-6 mb-2">Çalışma Alanları</h2>
            <div className="flex flex-wrap gap-2">
                {lawyer.expertise && lawyer.expertise.map((item, index) => (
                    <span key={index} className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm border border-gray-300">
                        {item}
                    </span>
                ))}
            </div>

            <h2 className="text-xl font-semibold mt-6 mb-2">Hakkımda</h2>
            {lawyer.aboutMe && lawyer.aboutMe.split("\n\n").map((paragraph, index) => (
                <p key={index} className="text-gray-700 mb-4">{paragraph}</p>
            ))}

            <h2 className="text-xl font-semibold mt-6 mb-2">Google Meeting Linki</h2>
            {lawyer.googleMeetingLink && (
                <p className="text-blue-500">
                    <a
                        href={lawyer.googleMeetingLink.startsWith("http") ? lawyer.googleMeetingLink : `https://${lawyer.googleMeetingLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {lawyer.googleMeetingLink}
                    </a>
                </p>
            )}

            {lawyer.baroCardURL && (
                <div className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">Baro Kimlik Kartının Resmi</h2>
                    <img
                        src={lawyer.baroCardURL}
                        alt="Baro Card"
                        className="h-20 w-20 rounded mb-4 cursor-pointer"
                        onClick={() => setIsModalOpen(true)}
                    />
                </div>
            )}

            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <img
                            src={lawyer.baroCardURL}
                            alt="Baro Card"
                            className="h-[500px] w-[500px] object-contain mb-4"
                        />
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
                        >
                            Kapat
                        </button>
                    </div>
                </div>
            )}

            {isResendModalOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                        <h2 className="text-xl font-semibold mb-4">Geri Gönder</h2>
                        <textarea
                            className="w-full p-2 border rounded"
                            rows="5"
                            placeholder="Geri gönderme nedeni yazın..."
                            value={reviewText}
                            onChange={(e) => setReviewText(e.target.value)}
                        />
                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition"
                                onClick={() => setIsResendModalOpen(false)}
                            >
                                Kapat
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                                onClick={handleResend}
                            >
                                Gönder
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LawyerReview;
