import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const LawyerPublicProfile = () => {
  const { id } = useParams();
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      const userDocRef = doc(db, "users", id);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setProfileData(userDoc.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchProfileData();
  }, [id]);

  if (!profileData) return <p>Yükleniyor...</p>;

  return (
    <div className="max-w-3xl mx-auto mt-28 my-10 p-8 bg-white shadow-md rounded-lg">
      <div className="flex items-center">
        {profileData.photoURL && (
          <img
            src={profileData.photoURL}
            alt={`${profileData.firstName} ${profileData.lastName}`}
            className="h-20 w-20 rounded-full mr-4"
          />
        )}
        <div>
          <h1 className="text-2xl font-bold">{`${profileData.firstName} ${profileData.lastName}`}</h1>
          <p className="text-gray-600">
            {profileData.city}, {profileData.district}
          </p>
          <p
            className={`text-sm ${profileData.status === true
              ? "text-green-500"
              : "text-red-500"
              }`}
          >
            {profileData.status === true ? "Çevrimiçi" : "Çevrimdışı"}
          </p>
        </div>
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-1">Çalışma Alanları</h2>
        <div className="flex flex-wrap gap-2">
          {profileData.expertise.map((item, index) => (
            <span
              key={index}
              className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm border border-gray-300"
            >
              {item}
            </span>
          ))}
        </div>
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold">Hakkımda</h2>
        {profileData.aboutMe.split("\n\n").map((paragraph, index) => (
          <p key={index} className="text-gray-700 mb-4">
            {paragraph}
          </p>
        ))}
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-semibold">İletişim</h2>
        <p className="text-gray-700 mb-2">
          <span className="font-bold">Email:</span> {profileData.email}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-bold">Telefon:</span> {profileData.phone}
        </p>
        {profileData.website && (
          <p className="text-gray-700 mb-2">
            <span className="font-bold">Web Sitesi:</span>{" "}
            <a
              href={`${profileData.website.startsWith("http")
                ? profileData.website
                : `https://${profileData.website}`
                }`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              {profileData.website}
            </a>
          </p>
        )}

        <p className="text-gray-700 mb-2">
          <span className="font-bold">Adres:</span> {profileData.address}
        </p>
      </div>
    </div>
  );
};

export default LawyerPublicProfile;
