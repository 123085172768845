import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db, storage } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
    ref as storageRef,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage";
import InputField from "../components/InputField";
import TextAreaField from "../components/TextAreaField";
import FileField from "../components/FileField";
import CityDistrictSelect from "../components/CityDistrictSelect";
import { expertiseOptions } from "../constants/expertiseOptions";
import { expertiseNumber, googleMeetingLink } from "../constants/appConstants";

const ResendEditProfile = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        website: "",
        city: "",
        district: "",
        address: "",
        barAssociation: "",
        barRegistrationNumber: "",
        googleMeetingLink: "",
        expertise: [],
        aboutMe: "",
        photo: null,
        baroCardPhoto: null,
    });

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [photo, setPhoto] = useState(null);
    const [baroCardPhoto, setBaroCardPhoto] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setFormData((prev) => ({
                        ...prev,
                        ...userData,
                    }));
                }
            }
        };
        fetchUserData();
    }, []);

    const validateForm = () => {
        let formErrors = {};
        if (!formData.firstName.trim()) formErrors.firstName = "İsim zorunludur.";
        if (!formData.lastName.trim()) formErrors.lastName = "Soyisim zorunludur.";
        if (!formData.phone.trim()) {
            formErrors.phone = "Telefon numarası zorunludur.";
        } else {
            const phoneRegex = /^[0-9]{10}$/;
            if (!phoneRegex.test(formData.phone)) {
                formErrors.phone =
                    "Telefon numarası 10 haneli olmalı ve boşluk içermemelidir.";
            }
        }
        if (!formData.city.trim()) formErrors.city = "İl zorunludur.";
        if (!formData.district.trim()) formErrors.district = "İlçe zorunludur.";
        if (!formData.address.trim()) formErrors.address = "Adres zorunludur.";
        if (!formData.barAssociation.trim())
            formErrors.barAssociation = "Kayıtlı baro zorunludur.";
        if (!formData.barRegistrationNumber.trim())
            formErrors.barRegistrationNumber = "Baro sicil numarası zorunludur.";
        if (formData.expertise.length === 0)
            formErrors.expertise = "En az bir uzmanlık alanı seçilmelidir.";
        if (formData.expertise.length > expertiseNumber)
            formErrors.expertise = `En fazla ${expertiseNumber} uzmanlık alanı seçebilirsiniz.`;
        if (!formData.aboutMe.trim())
            formErrors.aboutMe = "Hakkımda yazısı zorunludur.";
        if (!formData.googleMeetingLink.trim())
            formErrors.googleMeetingLink = "Google Meeting Linki zorunludur.";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleFileChange = (e, setFileFunc, sizeLimitMB) => {
        const file = e.target.files[0];
        const sizeLimit = sizeLimitMB * 1024 * 1024;

        if (file) {
            const validTypes = ["image/png", "image/jpeg", "image/jpg"];
            if (!validTypes.includes(file.type)) {
                setErrors({
                    ...errors,
                    [e.target.name]:
                        "Sadece PNG, JPG, veya JPEG dosyaları yükleyebilirsiniz.",
                });
                return;
            }

            if (file.size > sizeLimit) {
                setErrors({
                    ...errors,
                    [e.target.name]: `Dosya boyutu ${sizeLimitMB}MB'yi geçmemelidir.`,
                });
                return;
            }

            setErrors({ ...errors, [e.target.name]: "" });
            setFileFunc(file);
        }
    };

    const uploadFileToStorage = async (file, path) => {
        const fileRef = storageRef(storage, path);
        await uploadBytes(fileRef, file);
        return await getDownloadURL(fileRef);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");

        if (validateForm()) {
            setLoading(true);
            const user = auth.currentUser;

            try {
                const userDocRef = doc(db, "users", user.uid);

                const profilePhotoURL = photo
                    ? await uploadFileToStorage(photo, `users/${user.uid}/userProfilePhoto`)
                    : formData.photoURL;

                const baroCardURL = baroCardPhoto
                    ? await uploadFileToStorage(baroCardPhoto, `users/${user.uid}/baroCardPhoto`)
                    : formData.baroCardURL;

                await updateDoc(userDocRef, {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    phone: formData.phone,
                    website: formData.website,
                    city: formData.city,
                    district: formData.district,
                    address: formData.address,
                    barAssociation: formData.barAssociation,
                    barRegistrationNumber: formData.barRegistrationNumber,
                    expertise: formData.expertise,
                    aboutMe: formData.aboutMe,
                    photoURL: profilePhotoURL,
                    baroCardURL: baroCardURL,
                    googleMeetingLink: formData.googleMeetingLink,
                    isResend: false,
                    reviewText: "",
                });

                setSuccessMessage("Bilgiler başarıyla güncellendi.");
                navigate("/approval-pending");
            } catch (error) {
                setErrorMessage(`Hata oluştu: ${error.message}`);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="max-w-2xl mx-auto mt-28 my-10 p-8 bg-gray-100 shadow-md rounded-lg">
            <h1 className="text-4xl font-bold mb-8 text-center">Bilgileri Güncelle</h1>

            {formData.reviewText && (
                <div className="p-4 mb-4 bg-yellow-100 text-yellow-700 rounded">
                    {formData.reviewText}
                </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <InputField
                        label="İsim"
                        name="firstName"
                        type="text"
                        value={formData.firstName}
                        onChange={handleChange}
                        error={errors.firstName}
                        placeholder="İsim"
                        maxLength={40}
                    />
                    <InputField
                        label="Soyisim"
                        name="lastName"
                        type="text"
                        value={formData.lastName}
                        onChange={handleChange}
                        error={errors.lastName}
                        placeholder="Soyisim"
                        maxLength={40}
                    />
                    <InputField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        error={errors.email}
                        placeholder="Email"
                        disabled={true}
                    />
                    <InputField
                        label="Telefon Numarası"
                        name="phone"
                        type="text"
                        value={formData.phone}
                        onChange={handleChange}
                        error={errors.phone}
                        placeholder="Telefon Numarası (5xxxxxxxxx)"
                        maxLength={10}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                        }}
                    />
                    <InputField
                        label="İnternet Sitesi"
                        name="website"
                        type="text"
                        value={formData.website}
                        onChange={handleChange}
                        error={errors.website}
                        placeholder="İnternet Sitesi"
                        maxLength={200}
                    />
                </div>
                <CityDistrictSelect
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                />
                <TextAreaField
                    label="Adres"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    error={errors.address}
                    placeholder="Adres (Detay)"
                    maxLength={250}
                />
                <InputField
                    label="Kayıtlı Baro"
                    name="barAssociation"
                    type="text"
                    value={formData.barAssociation}
                    onChange={handleChange}
                    error={errors.barAssociation}
                    placeholder="Kayıtlı Baro"
                    maxLength={100}
                />
                <InputField
                    label="Baro Sicil No"
                    name="barRegistrationNumber"
                    type="text"
                    value={formData.barRegistrationNumber}
                    onChange={handleChange}
                    error={errors.barRegistrationNumber}
                    placeholder="Baro Sicil No"
                    maxLength={40}
                />
                <div className="flex items-center space-x-2">
                    <div className="flex-grow">
                        <InputField
                            label="Google Meeting Linki"
                            name="googleMeetingLink"
                            type="text"
                            value={formData.googleMeetingLink}
                            onChange={handleChange}
                            error={errors.googleMeetingLink}
                            placeholder="Google Meeting Linki"
                            maxLength={200}
                        />
                    </div>
                    <button
                        type="button"
                        className="w-8 h-8 flex items-center justify-center bg-blue-500 text-white rounded-full hover:bg-blue-600 transition"
                        onClick={() => window.open(googleMeetingLink, "_blank", "noopener,noreferrer")}
                        aria-label="Info"
                    >
                        ?
                    </button>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Çalışma Alanları (En fazla {expertiseNumber} alan seçebilirsiniz)
                    </label>
                    <div className="flex flex-wrap gap-2 mb-4">
                        {formData.expertise.map((option) => (
                            <div
                                key={option}
                                className="flex items-center bg-gray-200 text-gray-700 px-3 py-1 rounded-full cursor-pointer"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        expertise: formData.expertise.filter((item) => item !== option),
                                    })
                                }
                            >
                                {option}
                                <span className="ml-2 text-red-500 hover:text-red-700">
                                    &times;
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {expertiseOptions
                            .filter((option) => !formData.expertise.includes(option))
                            .map((option) => (
                                <div
                                    key={option}
                                    className="flex items-center bg-gray-100 hover:bg-gray-300 text-gray-700 px-3 py-1 rounded-full cursor-pointer border border-gray-300 transition duration-300"
                                    onClick={() =>
                                        setFormData({
                                            ...formData,
                                            expertise: [...formData.expertise, option],
                                        })
                                    }
                                >
                                    {option}
                                </div>
                            ))}
                    </div>
                    {errors.expertise && (
                        <p className="text-red-500 text-sm">{errors.expertise}</p>
                    )}
                </div>
                <TextAreaField
                    label="Hakkımda Yazısı"
                    name="aboutMe"
                    value={formData.aboutMe}
                    onChange={handleChange}
                    error={errors.aboutMe}
                    placeholder="Hakkımda Yazısı"
                    rows={6}
                    maxLength={1000}
                />
                <p className="text-sm text-gray-500 mb-4">
                    Eğer yeni bir fotoğraf yüklemezseniz, eski fotoğrafınız geçerli olacaktır.
                </p>
                <FileField
                    label="Profil Fotoğrafı Yükleyin"
                    name="photo"
                    onChange={(e) => handleFileChange(e, setPhoto, 2)}
                    error={errors.photo}
                />
                <FileField
                    label="Baro Kimlik Kartının Resmi"
                    name="baroCardPhoto"
                    onChange={(e) => handleFileChange(e, setBaroCardPhoto, 2)}
                    error={errors.baroCardPhoto}
                />
                <button
                    type="submit"
                    className="w-full border font-bold border-gray-600 text-gray-600 px-4 py-2 rounded-lg hover:bg-gray-600 hover:text-white transition duration-300 ease-in-out"
                >
                    {loading ? "Kaydediliyor..." : "Kaydet"}
                </button>
            </form>
            {successMessage && (
                <div className="mt-6 p-4 bg-green-100 text-green-700 rounded">
                    {successMessage}
                </div>
            )}
            {errorMessage && (
                <div className="mt-6 p-4 bg-red-100 text-red-700 rounded">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default ResendEditProfile;
