import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { collection, query, where, limit, getDocs } from "firebase/firestore";

const Reviews = () => {
    const [reviewUsers, setReviewUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchReviewUsers = async () => {
        setLoading(true);
        try {
            const q = query(
                collection(db, "users"),
                where("isReview", "==", true),
                limit(10)
            );
            const querySnapshot = await getDocs(q);

            const fetchedReviewUsers = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setReviewUsers(fetchedReviewUsers);
        } catch (error) {
            console.error("Error fetching users submitted for review: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReviewUsers();
    }, []);

    const handleCardClick = (user) => {
        navigate(`/dashboard/admin/lawyer-review-later/${user.id}`, { state: { user } });
    };

    if (loading) {
        return <div className="text-center mt-4">Yükleniyor...</div>;
    }

    return (
        <div className="p-6">
            <button
                className="mb-6 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300"
                onClick={fetchReviewUsers}
            >
                Tekrar Yükle
            </button>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {reviewUsers.length > 0 ? (
                    reviewUsers.map((user) => (
                        <div
                            key={user.id}
                            className="bg-white p-4 shadow-md rounded-lg cursor-pointer hover:bg-gray-100 transition duration-300"
                            onClick={() => handleCardClick(user)}
                        >
                            <h3 className="text-xl font-bold mb-2">{`${user.firstName} ${user.lastName}`}</h3>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-lg font-medium">İncelemeye gönderilen kullanıcı yok.</p>
                )}
            </div>
        </div>
    );
};

export default Reviews;
