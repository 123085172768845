const InputField = ({
  label,
  name,
  type = "text",
  value,
  onChange = () => { },
  error = null,
  onInput = () => { },
  placeholder,
  maxLength = null,
  disabled = false,
}) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={name}>
      {label}
    </label>
    <input
      type={type}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      onInput={onInput}
      placeholder={placeholder}
      maxLength={maxLength}
      className={`p-3 border w-full ${error ? "border-red-500" : "border-gray-300"} rounded shadow-sm`}
      disabled={disabled}
    />
    {error && <p className="text-red-500 text-sm">{error}</p>}
  </div>
);

export default InputField;
