import React, { useState, useEffect, useCallback } from "react";
import { auth, db, storage } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { expertiseOptions } from "../constants/expertiseOptions";
import UploadModal from "../components/UploadModal";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import PublicIcon from "@mui/icons-material/Public";
import InputField from "../components/InputField";
import TextAreaField from "../components/TextAreaField";
import CityDistrictSelect from "../components/CityDistrictSelect";
import { expertiseNumber, googleMeetingLink } from "../constants/appConstants";


const LawyerProfileManagement = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    website: "",
    city: "",
    district: "",
    address: "",
    barAssociation: "",
    barRegistrationNumber: "",
    googleMeetingLink: "",
    expertise: [],
    aboutMe: "",
    photoURL: "",
    status: false,
  });
  const [originalFormData, setOriginalFormData] = useState(formData);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [showPhotoUploader, setShowPhotoUploader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();

            if (
              typeof userData.expertise === "object" &&
              !Array.isArray(userData.expertise)
            ) {
              userData.expertise = Object.values(userData.expertise);
            }

            setFormData((prevFormData) => ({
              ...prevFormData,
              ...userData,
            }));

            if (!userData.status) {
              handleStatusToggle(true);
            }
          }
        } catch (error) {
          setErrorMessage("Veriler getirilirken hata oluştu.");
        }
      };

      fetchUserData();
    } else {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleStatusToggle = useCallback(
    async (newStatus = null) => {
      const status = newStatus !== null ? newStatus : !formData.status;
      setFormData((prevFormData) => ({
        ...prevFormData,
        status,
      }));

      try {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, { status });
        setSuccessMessage(
          `Durum başarıyla ${newStatus ? "çevrimiçi" : "çevrimdışı"
          } olarak güncellendi.`
        );
      } catch (error) {
        setErrorMessage(`Hata oluştu: ${error.message}`);
      }
    },
    [formData, user]
  );

  const validateForm = () => {
    let formErrors = {};

    if (!formData.phone.trim()) {
      formErrors.phone = "Telefon numarası zorunludur.";
    } else {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(formData.phone)) {
        formErrors.phone =
          "Telefon numarası 10 haneli olmalı ve boşluk içermemelidir.";
      }
    }
    if (!formData.city.trim()) formErrors.city = "İl zorunludur.";
    if (!formData.district.trim()) formErrors.district = "İlçe zorunludur.";
    if (!formData.address.trim()) formErrors.address = "Adres zorunludur.";
    if (formData.expertise.length === 0)
      formErrors.expertise = "En az bir çalışma alanı seçilmelidir.";
    if (formData.expertise.length > expertiseNumber)
      formErrors.expertise = `En fazla ${expertiseNumber} çalışma alanı seçebilirsiniz.`;
    if (!formData.aboutMe.trim())
      formErrors.aboutMe = "Hakkımda yazısı zorunludur.";
    if (!formData.googleMeetingLink.trim())
      formErrors.googleMeetingLink = "Google Meeting Linki Zorunludur";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors({ ...errors, [name]: "" });

  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
    const previewURL = URL.createObjectURL(file);
    setPhotoPreview(previewURL);
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleExpertiseSelect = (option) => {
    let updatedExpertise = [...formData.expertise];

    if (updatedExpertise.includes(option)) {
      updatedExpertise = updatedExpertise.filter((item) => item !== option);
    } else if (updatedExpertise.length < expertiseNumber) {
      updatedExpertise.push(option);
    }

    setFormData({ ...formData, expertise: updatedExpertise });
  };

  const handleRemoveExpertise = (option) => {
    setFormData({
      ...formData,
      expertise: formData.expertise.filter((item) => item !== option),
    });
  };

  const handlePhotoUpdate = async () => {
    if (photo) {
      setLoading(true);
      setUploadProgress(0);
      try {
        const storageReference = storageRef(
          storage,
          `users/${user.uid}/userProfilePhoto`
        );
        const uploadTask = uploadBytesResumable(storageReference, photo);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            setErrorMessage(`Hata oluştu: ${error.message}`);
            setLoading(false);
          },
          async () => {
            const photoURL = await getDownloadURL(storageReference);
            setFormData((prevFormData) => ({
              ...prevFormData,
              photoURL,
            }));
            const userDocRef = doc(db, "users", user.uid);
            await updateDoc(userDocRef, { photoURL });
            setSuccessMessage("Profil fotoğrafı başarıyla güncellendi.");
            setShowPhotoUploader(false);
            setLoading(false);
          }
        );
      } catch (error) {
        setErrorMessage(`Hata oluştu: ${error.message}`);
        setLoading(false);
      }
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      setFormData(originalFormData);
      setErrors({})
    } else {
      setOriginalFormData(formData);
      setSuccessMessage("");
      setErrorMessage("");
    }
    setEditMode(!editMode);
  };

  const handleSignOut = async () => {
    await handleStatusToggle(false);
    auth.signOut();
    navigate("/login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      formData.googleMeetingLink &&
      !formData.googleMeetingLink.startsWith("http://") &&
      !formData.googleMeetingLink.startsWith("https://")
    ) {
      formData.googleMeetingLink = `https://${formData.googleMeetingLink}`;
    }

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      const userDocRef = doc(db, "users", user.uid);
      const updatedFields = {};

      if (formData.aboutMe !== originalFormData.aboutMe) {
        updatedFields.updatedAboutMe = formData.aboutMe;
      }

      if (formData.googleMeetingLink !== originalFormData.googleMeetingLink) {
        updatedFields.updatedGoogleMeetingLink = formData.googleMeetingLink;
      }

      if (formData.website !== originalFormData.website) {
        updatedFields.updatedWebsite = formData.website;
      }

      if (formData.address !== originalFormData.address) {
        updatedFields.updatedAddress = formData.address;
      }

      updatedFields.phone = formData.phone;
      updatedFields.city = formData.city;
      updatedFields.district = formData.district;
      updatedFields.expertise = formData.expertise;

      if (Object.keys(updatedFields).length > 4) {
        updatedFields.isReview = true;
        await updateDoc(userDocRef, updatedFields);
        setSuccessMessage("Güncellemeleriniz incelemeye gönderildi.");
      } else {
        await updateDoc(userDocRef, updatedFields);
        setSuccessMessage("Profil başarıyla güncellendi.");
      }

      setEditMode(false);
    } catch (error) {
      setErrorMessage(`Hata oluştu: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };


  const handleViewProfile = () => {
    navigate(`/profile/${auth.currentUser.uid}`);
  };

  const renderReviewUpdates = () => {
    const updates = [];

    if (formData.updatedAboutMe) {
      updates.push(<p key="aboutMe">Hakkımda: {formData.updatedAboutMe}</p>);
    }
    if (formData.updatedGoogleMeetingLink) {
      updates.push(
        <p key="googleMeetingLink">Google Meeting Linki: {formData.updatedGoogleMeetingLink}</p>
      );
    }
    if (formData.updatedWebsite) {
      updates.push(<p key="website">Web Sitesi: {formData.updatedWebsite}</p>);
    }
    if (formData.updatedAddress) {
      updates.push(<p key="address">Adres: {formData.updatedAddress}</p>);
    }

    if (updates.length > 0) {
      return (
        <div className="p-4 mb-4 bg-yellow-100 text-yellow-800 rounded-md">
          <h3 className="font-semibold mb-2">Bekleyen Güncellemeler:</h3>
          {updates}
        </div>
      );
    }
    return null;
  };


  return (
    <div className="max-w-4xl mx-auto mt-28 my-10 p-8 bg-gray-100 shadow-md rounded-lg">
      {formData.isReview && renderReviewUpdates()}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-4xl font-bold">Profil Yönetimi</h1>
        <div className="flex">
          <button
            onClick={handleViewProfile}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
          >
            <PublicIcon className="mr-2" />
            Profil Önizleme
          </button>
          <button
            onClick={toggleEditMode}
            className={`ml-4 px-4 py-2 rounded-lg transition duration-300 flex items-center justify-center ${editMode
              ? "bg-blue-600 text-white hover:bg-blue-700"
              : "bg-gray-600 text-white hover:bg-gray-700"
              }`}
          >
            <EditIcon />
          </button>
          <button
            onClick={handleSignOut}
            className="ml-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300 flex items-center justify-center"
          >
            <LogoutIcon />
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6 ml-10">
        <div className="flex flex-col items-center">
          {formData.photoURL && (
            <img
              src={formData.photoURL}
              alt="Profil Fotoğrafı"
              className="w-24 h-24 rounded-full mb-2"
            />
          )}
          <div className="flex flex-col items-center">
            <button
              type="button"
              onClick={() => setShowPhotoUploader(true)}
              className="text-blue-600 hover:underline mt-2"
            >
              Değiştir
            </button>
          </div>
        </div>

        <div className="flex items-center">
          <span className="text-sm font-medium text-gray-700 mr-2">Durum:</span>
          <div
            onClick={() => handleStatusToggle()}
            className={`w-16 h-8 flex items-center rounded-full p-1 cursor-pointer ${formData.status ? "bg-green-500" : "bg-red-500"
              }`}
          >
            <div
              className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${formData.status ? "translate-x-8" : ""
                }`}
            ></div>
          </div>
        </div>
      </div>

      {showPhotoUploader && (
        <UploadModal
          photoPreview={photoPreview}
          handleFileChange={handleFileChange}
          handlePhotoUpdate={handlePhotoUpdate}
          loading={loading}
          uploadProgress={uploadProgress}
          setShowPhotoUploader={setShowPhotoUploader}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            label="İsim"
            name="firstName"
            type="text"
            value={formData.firstName}
            placeholder="İsim"
            disabled={true}
          />
          <InputField
            label="Soyisim"
            name="lastName"
            type="text"
            value={formData.lastName}
            placeholder="Soyisim"
            disabled={true}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            placeholder="Email"
            disabled={true}
          />
          <InputField
            label="Telefon Numarası"
            name="phone"
            type="text"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            placeholder="Telefon Numarası (5xxxxxxxxx)"
            maxLength={10}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            disabled={!editMode}
          />
        </div>
        <CityDistrictSelect
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          disabled={!editMode}
        />
        <InputField
          label={`İnternet Sitesi ${editMode ? "(*)" : ""}`}
          name="website"
          type="text"
          value={formData.website}
          onChange={handleChange}
          error={errors.website}
          placeholder="İnternet Sitesi"
          maxLength={200}
          disabled={!editMode}
        />
        <TextAreaField
          label={`Adres ${editMode ? "(*)" : ""}`}
          name="address"
          value={formData.address}
          onChange={handleChange}
          error={errors.address}
          placeholder="Adres (Detay)"
          maxLength={250}
          disabled={!editMode}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            label="Kayıtlı Baro"
            name="barAssociation"
            type="text"
            value={formData.barAssociation}
            placeholder="Kayıtlı Baro"
            disabled={true}
          />
          <InputField
            label="Baro Sicil No"
            name="barRegistrationNumber"
            type="text"
            value={formData.barRegistrationNumber}
            placeholder="Baro Sicil No"
            disabled={true}
          />
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex-grow">
            <InputField
              label={`Google Meeting Linki ${editMode ? "(*)" : ""}`}
              name="googleMeetingLink"
              type="text"
              value={formData.googleMeetingLink}
              onChange={handleChange}
              error={errors.googleMeetingLink}
              placeholder="Google Meeting Linki"
              maxLength={200}
              disabled={!editMode}
            />
          </div>
          {editMode && (
            <button
              type="button"
              className="w-8 h-8 flex items-center justify-center bg-blue-500 text-white rounded-full hover:bg-blue-600 transition"
              onClick={() => window.open(googleMeetingLink, "_blank", "noopener,noreferrer")}
              aria-label="Info"
            >
              ?
            </button>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Çalışma Alanları (En fazla {expertiseNumber} alan seçebilirsiniz)
          </label>

          {editMode ? (
            <>
              <div className="flex flex-wrap gap-2 mb-4">
                {formData.expertise.map((option) => (
                  <div
                    key={option}
                    className="flex items-center bg-gray-200 text-gray-700 px-3 py-1 rounded-full cursor-pointer"
                    onClick={() => handleRemoveExpertise(option)}
                  >
                    {option}
                    <span className="ml-2 text-red-500 hover:text-red-700">
                      &times;
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex flex-wrap gap-2">
                {expertiseOptions
                  .filter((option) => !formData.expertise.includes(option))
                  .map((option) => (
                    <div
                      key={option}
                      className="flex items-center bg-gray-100 hover:bg-gray-300 text-gray-700 px-3 py-1 rounded-full cursor-pointer border border-gray-300 transition duration-300"
                      onClick={() => handleExpertiseSelect(option)}
                      draggable
                      onDragStart={(e) =>
                        e.dataTransfer.setData("text/plain", option)
                      }
                      onDrop={(e) => {
                        e.preventDefault();
                        handleExpertiseSelect(
                          e.dataTransfer.getData("text/plain")
                        );
                      }}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      {option}
                    </div>
                  ))}
                {errors.expertise && (
                  <p className="text-red-500 text-sm">{errors.expertise}</p>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-wrap">
              {formData.expertise.map((item) => (
                <span
                  key={item}
                  className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm mr-2 mb-2"
                >
                  {item}
                </span>
              ))}
            </div>
          )}
        </div>
        <TextAreaField
          label={`Hakkımda Yazısı ${editMode ? "(*)" : ""}`}
          name="aboutMe"
          value={formData.aboutMe}
          onChange={handleChange}
          error={errors.aboutMe}
          placeholder="Hakkımda Yazısı"
          rows={6}
          maxLength={1000}
          disabled={!editMode}
        />

        {editMode && (
          <p className="text-sm text-gray-700 mt-4">
            (*) Bu alanları güncellerseniz, değişiklikleriniz yayınlanmadan önce Avukat Bul ekibi tarafından incelenecektir.
          </p>
        )}

        {editMode && (
          <button
            type="submit"
            className="w-full border font-bold border-gray-600 text-gray-600 px-4 py-2 rounded-lg hover:bg-gray-600 hover:text-white transition duration-300 ease-in-out"
          >
            {loading ? "Güncelleniyor..." : "Güncelle"}
          </button>
        )}
      </form>

      {errorMessage && (
        <div className="mt-6 p-4 bg-red-100 text-red-700 rounded">
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="mt-6 p-4 bg-green-100 text-green-700 rounded">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default LawyerProfileManagement;
