import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { collection, query, where, limit, getDocs } from "firebase/firestore";

const NewUsers = () => {
    const [lawyers, setLawyers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchLawyers = async () => {
        setLoading(true);
        try {
            const q = query(
                collection(db, "users"),
                where("isApproved", "==", false),
                where("isResend", "==", false),
                limit(10)
            );
            const querySnapshot = await getDocs(q);

            const fetchedLawyers = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setLawyers(fetchedLawyers);
        } catch (error) {
            console.error("Error fetching lawyers: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLawyers();
    }, []);

    const handleCardClick = (lawyer) => {
        navigate(`/dashboard/admin/lawyer-review/${lawyer.id}`, { state: { lawyer } });
    };

    if (loading) {
        return <div className="text-center mt-4">Yükleniyor...</div>;
    }

    return (
        <div className="p-6">
            <button
                className="mb-6 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300"
                onClick={fetchLawyers}
            >
                Tekrar Yükle
            </button>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {lawyers.length > 0 ? (
                    lawyers.map((lawyer) => (
                        <div
                            key={lawyer.id}
                            className="bg-white p-4 shadow-md rounded-lg cursor-pointer hover:bg-gray-100 transition duration-300"
                            onClick={() => handleCardClick(lawyer)}
                        >
                            <h3 className="text-xl font-bold mb-2">{`${lawyer.firstName} ${lawyer.lastName}`}</h3>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-lg font-medium">Onay bekleyen kullanıcı yok.</p>
                )}
            </div>
        </div>
    );
};

export default NewUsers;
